import React, { FC, useRef, useState } from "react";
import { WrapperTransaction } from "./styled";
import { Wrapper } from "../../../Dashboard/DashboardChart/styled";
import DataTable from "react-data-table-component";
import { format } from "date-fns";
import {isNumber} from "chart.js/helpers";


interface IProps {
  show: boolean
  customerInformation: any,
  productList: any,
  setShow: any
  customer: any;
  staffPage?: boolean
}

const TransactionInformation: FC<IProps> = ({ show, customerInformation, productList, setShow, customer, staffPage = false  }) => {
  const chartRef = useRef<HTMLDivElement>(null);
  const [activeChart, setActiveChart] = useState<any>("Items Purchased");
  const handleChangeActiveChart = (chartName: any) => {
    setActiveChart(chartName);
    // todo
    // setTimeout(() => {
    //   chartRef.current?.scrollIntoView({
    //     behavior: "smooth",
    //     block: "end",
    //     inline: "nearest",
    //   });
    // }, 200);
  };

  // const hideTransactionDetail = () => setShow(false);

  const productsListColumns = [
    {
      name: "Product",
      width: "350px",
      compact: true,
      cell: (row: any): JSX.Element => {
        return (
          <div>
            {row.title}
          </div>
        );
      }
    },
    {
      name: "SKU",
      minWidth: "150px",
      compact: true,
      cell: (row: any): JSX.Element => {
        return (
          <div>
            {row.squ}
          </div>
        );
      }
    },

    {
      name: "Quantity",
      compact: true,
      cell: (row: any): JSX.Element => {
        return <div>{row.quantity}</div>;
      }
    },
    {
      name: "Price",
      compact: true,
      cell: (row: any): JSX.Element => {
        return <div>{`${customerInformation.currency}${parseFloat(`${+row.initial_price / +row.quantity}`).toFixed(2)}`}</div>;
      }
    },
    {
      name: "Member Price",
      compact: true,
      cell: (row: any): JSX.Element => {
        return <div>{`${customerInformation.currency}${parseFloat(`${+row.price / +row.quantity}`).toFixed(2)}`}</div>;
      }
    },
    {
      name: "Discount",
      compact: true,
      cell: (row: any): JSX.Element => {
        return <div>{`${customerInformation.currency}${parseFloat(`${+row.initial_price - +row.price}`).toFixed(2)}`}</div>;
      }
    },
    {
      name: "Points Earned",
      omit: staffPage,
      compact: true,
      cell: (row: any): JSX.Element => {
        return <div>{row.points_earned ? `${parseFloat(`${+row.points_earned}`)}` : '0'}</div>;
      }
    },
    {
      name: "Total",
      minWidth: "120px",
      compact: true,
      right: true,
      cell: (row: any): JSX.Element => {
        return (
          <div>
            {`${customerInformation.currency}${parseFloat(row.total).toFixed(2)}`}
          </div>
        );
      }
    }
  ];

  function vouchersName(vouchers: any[]) {
    return vouchers.reduce((acc: any, voucher: any) => {
      const { reward_name } = voucher;
      acc.push( reward_name);
      return acc;
    }, []).join(", ") || "-";
  }
  return (
    <>
      {show ? <WrapperTransaction>
        <div className="transactionWrapper">
          {/*<div className="transaction-header">*/}
          {/*  <div className="topBar" data-tag="allowRowEvents" onClick={hideTransactionDetail}>*/}
          {/*    <BackIcon /> Back to list*/}
          {/*  </div>*/}
          {/*</div>*/}
          <div className="transaction-body">
            <h2 className={"transaction-title"}>Transactions</h2>
            {/*<p className={"transaction-description"}></p>*/}
            <Wrapper>
              <div className="nav-subpages">
                <div
                  className={`nav-subpage ${
                    activeChart === "Items Purchased" ? "active-page" : ""
                  }`}
                >
                  <button
                    type="button"
                    onClick={() => handleChangeActiveChart("Items Purchased")}
                  >
                    Items Purchased
                  </button>
                </div>
                {/*<div*/}
                {/*  className={`nav-subpage ${*/}
                {/*    activeChart === "Points Activity" ? "active-page" : ""*/}
                {/*  }`}*/}
                {/*>*/}
                {/*  <button*/}
                {/*    type="button"*/}
                {/*    disabled={true}*/}
                {/*    onClick={() => handleChangeActiveChart("Points Activity")}*/}
                {/*  >*/}
                {/*    Points Activity*/}
                {/*  </button>*/}
                {/*</div>*/}
              </div>
              <div ref={chartRef} className="chart-wrapper">
                <div className="chart">
                  {activeChart === "Items Purchased" ? (
                    <>
                      <div className="purchase-wrapper">
                        <div className="main-info">
                          <div className="main-info-item">
                            <p className={"item-label"}>Order</p>
                            <p className={"item-value"}>{customerInformation.orderId}</p>
                          </div>
                          <div className="main-info-item">
                            <p className={"item-label"}>Date</p>
                            <p className={"item-value"}>{customerInformation.date
                              ? format(new Date(isNumber(customerInformation.date) ? customerInformation.date*1000 : customerInformation.date), "dd MMM yyyy")
                              : "-"}</p>
                          </div>
                          {!staffPage && <div className="main-info-item">
                              <p className={"item-label"}>Points Earned</p>
                              <p className={"item-value"}>{`${customer.points}`}</p>
                          </div>}
                          {!staffPage &&
                          <div className="main-info-item">
                            <p className={"item-label"}>Used Vouchers</p>
                            <p className={"item-value"}>{customer.used_vouchers ? vouchersName(customer.used_vouchers) : ''}</p>
                            </div>}
                          <div className="main-info-item">
                            <p className={"item-label"}>Customer</p>
                            <p className={"item-value"}>{customerInformation.customerName}</p>
                          </div>
                          <div className="main-info-item">
                            <p className={"item-label"}>Location</p>
                            <p className={"item-value"}>{customerInformation.location}</p>
                          </div>

                        </div>
                        <DataTable columns={productsListColumns} data={productList} noHeader />
                        <div className="total_wrap" style={!!customer.welcome_points_gift ? {justifyContent: "space-between"} : {justifyContent: 'flex-end'}}>
                          {!!customer.welcome_points_gift ? <div className="total-prise">
                            <p style={{marginLeft: 0}}>Welcome Gift Points: </p>
                            <p>{`${customer.welcome_points_gift}`}</p>
                          </div> : null}
                          <div style={{display: "flex", alignItems: 'center'}}>
                            {parseFloat(customerInformation.shippingPrice) > 0 && <div className="total-prise">
                                <p>Shipping: </p>
                                <p>{`${customerInformation.currency}${parseFloat(customerInformation.shippingPrice).toFixed(2)}`}</p>
                            </div>}
                            
                            <div className="total-prise">
                              <p>Total: </p>
                              <p>{`${customerInformation.currency}${parseFloat(customerInformation.totalPrice).toFixed(2)}`}</p>
                            </div>
                          </div>
                        
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <h2>Points Activity</h2>
                    </>
                  )}
                </div>
              </div>
            </Wrapper>
          </div>
        </div>

      </WrapperTransaction> : null}

    </>

  );
};

export default TransactionInformation;
