import React, { FC, useEffect, useState } from "react";
import DataTable from "react-data-table-component";

import userDefaultImg from "src/assets/img/default-user.jpg";
import { CustomerUserInterface } from "src/types/customer";
import { WrapperTableList } from "./styled";
import PlaceUsersList from "./PlaceUsersList";
import {getAllUserForBusinessPlaceAxios} from "../../../../api/adminPanel";
import { ReactComponent as CheckIcon } from "src/assets/img/checkCircle.svg";

interface IProps {
  data: any;
  getCustomerList: any;
  requestParamtrs: any;
}

const ExpandableTableList: FC<IProps> = ({ data, getCustomerList, requestParamtrs }) => {
  const [userInfo, setUserInfo] = useState<Array<CustomerUserInterface> | null>(null);
const [locationId, setLocationId] = useState<number>(0)
const [store, setStore] = useState<string>('')
const [placeUsers, setPlaseUsers] = useState<any[]>([])
  useEffect(() => {
    // getUserPointInfo(data.id);
    setUserInfo(data.place.locations)
  }, [data.id, requestParamtrs]);
 
const getAllUsersForPlace = (id: any) => {
  getAllUserForBusinessPlaceAxios({id}).then(res => {
    setPlaseUsers(res.data.customers)
  }).catch(e=>{
    console.log(e)
  })
}

  const customerTableColumns = [
    {
      name: "Icon",
      width: "50px",
      cell: (row: any): JSX.Element => {
        return (
          <div className="photo">
            {row?.image ? (
              <img src={row.image} alt="" />
            ) : (
              <img src={userDefaultImg} alt="" />
            )}
          </div>
        );
      }
    },
    {
      name: "Name",
      minWidth: "150px",
      cell: (row: any): JSX.Element => {
        return (
          <div>
            {row.location_name}
          </div>
        );
      }
    },
    {
      name: "loyalty Program",
      cell: (row: any): JSX.Element => {
        return <div>{data.place.promotion}</div>;
      }
    },
    {
      name: "Phone",
      cell: (row: any): JSX.Element => {
        return <div>{row.phone_number}</div>;
      }
    },
    {
      name: "Address",
      cell: (row: any): JSX.Element => {
        return <div>{row.address || "-"}</div>;
      }
    },
    
  ];
  const [listRow, setListRow] = useState<any>({});
  const [allowFunctionality, setAllowFunctionality] = useState<any>([]);
  useEffect(() => {
    setAllowFunctionality(Object.keys(data).filter(key=>key.includes('allow')).filter(item=> data[item] === true))
  }, []);
  
  return (
    <WrapperTableList>
      {!!userInfo &&
          <>
              <div className="allow_functions">
                  <h2 className={'allow_title'}>Allowed functionality</h2>
                  <div className="functionality_list">
                    
                    {allowFunctionality.length > 0 ? allowFunctionality.map((item: any) => <div className={'allow_item'}
                                                                                                key={'item.' + item}
                                                                                                style={{textTransform: 'capitalize'}}>
                        <CheckIcon/>{item === 'allow_pos' ? 'allow pos rules' : item.replaceAll('_', ' ')}</div>) :
                      <div>Not allowed function</div>}
                  </div>
              </div>
              <DataTable
                  data={userInfo}
                  columns={customerTableColumns}
                  noHeader
                  onRowClicked={(row: any) => {
                    getAllUsersForPlace(row.id)
                    setLocationId(row.id)
                    setStore(row.location_name)
                  }}
                  expandableRows
                  expandableRowsHideExpander={true}
                  expandableRowExpanded={(row) => (row === listRow)}
                  onRowExpandToggled={(bool, row) => setListRow(row)}
                  expandOnRowClicked={true}
                  expandableRowsComponent={
                    <PlaceUsersList users={placeUsers} locationId={locationId} promotion={data.place.promotion}
                                    store={store}/>
                  }
              />

          </>
      }
    </WrapperTableList>
  
  );
};

export default ExpandableTableList;
